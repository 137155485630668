<template>
  <div>
    <list-template
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      :current-page="page"
      @onHandle="handleClick"
      @onExportData="handDownload"
      @onBatchImport ="handBatchImport"
      @onSearch="onSearch"
      @onReset="onReset"
      @onChangePage="handleCurrentChange"
      @onChange="onChange"
    />
    <Dialog :visible.sync="visible" showLine :title="title" :size="size" :beforeSubmit="onBeforeClose" :showFooter="showFooter" :closeOnClickModal="closeOnClickModal">
      <template v-if="!visibleType">
        <div class="tw">请下载模板后，在模板内填充数据，再上传数据</div>
        <div class="px-2">
          <LineWrapper labelWidth="100rem" labelColor="#222">
            <LineItem label="下载模板">
              <el-button size="small" icon="el-icon-download" @click="handleDownFile">下载</el-button>
            </LineItem>
            <LineItem label="上传数据">
              <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleSelectFile">上传</el-button>
            </LineItem>
          </LineWrapper>
        </div>
      </template>
      <template v-else-if="visibleType === 1">
        <el-form ref="form" :model="formData" :rules="rules" label-position="left" label-width="120rem">
          <el-form-item label="学生姓名">{{ info.student_name }}</el-form-item>
          <el-form-item label="高中入学年份">{{ info.year }}</el-form-item>
          <el-form-item label="初中就读校区">{{ info.middle_school_text }}</el-form-item>
          <el-form-item label="初中班主任" prop="middle_teacher">
            <el-input v-model="formData.middle_teacher" placeholder="请输入" :maxlength="20"></el-input>
          </el-form-item>
          <el-form-item label="初中学校部长" prop="middle_minister">
            <el-input v-model="formData.middle_minister" placeholder="请输入" :maxlength="20"></el-input>
          </el-form-item>
        </el-form>
      </template>
      <template v-else>
        <TimeLine :list="list" reverse v-loading="dialogLoading"></TimeLine>
      </template>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/common/Dialog'
import LineItem from '@/components/common/LineItem'
import LineWrapper from '@/components/common/LineWrapper'
import TimeLine from '@/components/common/TimeLine'
import { tableListMixin } from '@/utils/mixins'
import { setConfigOption, handleChooseFile } from '@/utils'
import http, { fileDownload, zs_opt_history } from '@/api'
export default {
  _config:{'route':{'path':'list','meta':{'title':'列表','keepAlive':true}}},
  mixins: [tableListMixin],
  components: { Dialog, LineItem, LineWrapper, TimeLine },
  data() {
    return {
      dialogLoading: false,
      visibleType: 0,
      visible: false,
      showFooter: false,
      closeOnClickModal: false,
      title: '',
      size: '',
      searchConfig: [
        { placeholder: '搜索学生姓名', prop: 'student_name' },
        { placeholder: '搜索初中班主任', prop: 'middle_teacher' },
        { placeholder: '搜索初中学校部长', prop: 'middle_minister' },
        { placeholder: '筛选初中就读校区', prop: 'middle_school', tag: 'select', options: [] },
        { placeholder: '是否参加正式报名', prop: 'official_registration', tag: 'select', options: [{ label: '是', value: 1 }, { label: '否', value: 0 }] }
      ],
      tableConfig: [
        { prop: 'student_name', label: '学生姓名' },
        { prop: 'id_card', label: '学生身份证号' },
        { prop: 'year', label: '高中入学年份' },
        { prop: 'middle_school_text', label: '初中就读校区' },
        { prop: 'middle_teacher', label: '初中班主任' },
        { prop: 'middle_minister', label: '初中学校部长' },
        { prop: 'official_registration_text', label: '是否参加正式报名' },
        { prop: 'creator', label: '录入人' },
        { prop: 'created_at', label: '录入时间' },
        { label: '操作', width: '230rem', handle: true }
      ],
      info: { student_name: '', year: '', school: '' },
      formData: { middle_teacher: '',middle_minister: '', id: '' },
      rules: {
        middle_teacher: [{ required: true, message: '请输入', trigger: 'change' }],
        middle_minister: [{ required: true, message: '请输入', trigger: 'change' }],
      },
    }
  },
  created() {
    this.getConfig()
  },
  methods: {
    getConfig() {
      http({ url: '/recruit/common/consulting-school', pre: 'zs' }).then(res => {
        const { entity_school = [] } = res
        setConfigOption({ list: this.searchConfig, data: entity_school, key: 'middle_school', value: 'id', label: 'school_name' })
      })
    },
    getData() {
      this.getDataApi('/zhaosheng/api/recruit-2024/school-helicopter/get-list')
    },
    handDownload() {
      fileDownload('/zhaosheng/api/recruit-2024/school-helicopter/export', {...this.search}, { name: '本校直升人员', show: true })
    },
    handleClick(row, handle) {
      const { id } = row
      const { type } = handle
      this.showFooter = type === 'edit'
      this.closeOnClickModal = type !== 'edit'
      if (type === 'edit') {
        this.title = '基础信息'
        this.size = 'default'
        this.visibleType = 1
        this.visible = true
        const { middle_teacher, middle_minister, student_name, year, middle_school_text } = row
        this.info = { student_name, year, middle_school_text }
        this.formData = { middle_teacher, middle_minister, id }
      } else if (type === 'viewHistory') {
        this.title = '历史记录'
        this.size = 'default'
        this.visibleType = 2
        this.visible = true
        this.getHistory(id)
      } else if (type === 'delete') {
        this.$msgbox({
          title: '提示',
          message: `是否删除【${row.student_name}】的信息?`,
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: '删除',
          cancelButtonText: '点错了',
          beforeClose: (action, instance, done) => {
            if (instance.confirmButtonLoading) return this.$message.warning('请求中，请稍后')
            if (action !== 'confirm') return done()
            instance.confirmButtonLoading = true
            instance.confirmButtonText = '删除中...'
            http({ url: `/recruit-2024/school-helicopter/delete?id=${id}`, pre: 'zs' }).then(() => {
              this.getData()
              this.$message({ message: '删除成功', type: 'success' })
              done()
            }).catch(() => {
              instance.confirmButtonText = '删除'
            }).finally(() => {
              instance.confirmButtonLoading = false
            })
          }
        })
      }
    },
    onBeforeClose(done, instance) {
      this.$refs.form.validate((valid) => {
        if(valid) {
          const url = '/recruit-2024/school-helicopter/update'
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = '保存中...';
          http({ url, method: 'post', pre: 'zs'}, this.formData).then(() => {
            this.$message.success('编辑成功');
            this.getData()
            done()
          }).catch(() => {
            instance.confirmButtonLoading = false;
            instance.confirmButtonText = '确定';
          })
        }
      })
    },
    getHistory(id) {
      this.list = []
      this.dialogLoading = true
      zs_opt_history({ type: 'direct_student', id }).then(res => {
        const { list } = res
        this.list = list.map(i => ({ content: i.change_detail }))
      }).finally(() => this.dialogLoading = false)
    },
    handBatchImport () {
      this.title = '批量导入'
      this.size = 'small'
      this.visibleType = 0
      this.visible = true
      this.showFooter = false
      this.closeOnClickModal = false
    },
    handleDownFile() {
      const params = { name: '本校直升人员', type: 'xlsx' }
      const api = `${process.env.VUE_APP_URL_OSS}setting/school-helicopter.xlsx`
      fileDownload(api, {}, params).then(() => {
        this.visible = false
      })
    },
    handleSelectFile () {
      handleChooseFile({
        api: '/zhaosheng/api/recruit-2024/school-helicopter/import',
        show: true,
        onSuccess: () => {
          this.visible = false
          this.$message.success({ message: '导入成功！', duration: 3000 })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
